import { faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import React from "react";
import Bubble from "../components/bubble";
import { getLocalizedLink } from "../helper/link";
import CookieBanner from "./cookieBanner";
import Footer from "./footer";
import Header from "./header";

const Layout = ({ children, siteSettings }) => {
  return (
    <>
      <div id="top"></div>
      <Header siteSettings={siteSettings} />
      <div className="bubbles">
        <Bubble
          icon={faPenSquare}
          text="Blog"
          link={getLocalizedLink("/blog", siteSettings.node_locale)}
        />
        <Bubble
          icon={faYoutube}
          text="Youtube"
          link="https://www.youtube.com/channel/UCRUG7JtOD6__03MPNzVUhng"
          target="_blank"
        />
        <Bubble
          icon={faTwitter}
          text="Twitter"
          link="https://twitter.com/frient_news"
          target="_blank"
        />
      </div>
      <div className="layout__content">{children}</div>

      <Footer siteSettings={siteSettings} />
      <CookieBanner microcopy={siteSettings.microcopy} />
    </>
  );
};

export const pageQuery = graphql`
  fragment settings on ContentfulSiteSettingsConnection {
    edges {
      node {
        siteName
        siteDescription {
          siteDescription
        }
        siteAuthor
        copyright
        navigation {
          childPages {
            localizedPage {
              name
            }
          }
          localizedPage {
            name
            contentComponents {
              ... on ContentfulComponentPageHeader {
                images {
                  image {
                    fluid {
                      src
                      srcSet
                      srcSetWebp
                      srcWebp
                    }
                  }
                }
              }
            }
            shortDescription {
              content {
                content {
                  value
                }
              }
            }
          }
        }
        logo {
          file {
            url
          }
        }
        logoMobile {
          file {
            url
          }
        }
        logoSticky {
          file {
            url
          }
        }
        node_locale
      }
    }
  }
`;

export default Layout;
