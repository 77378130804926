/* Global imports */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import PropTypes from "prop-types";
import React from "react";

/* Local imports */

/* Component definition */
const Checkbox = ({
  className,
  text,
  value,
  name,
  checked,
  onToggle,
  disabled,
  required,
  onInvalid,
}) => {
  return (
    <label
      className={`checkbox ${disabled ? "checkbox--disabled" : ""} ${
        required ? "checkbox--required" : ""
      }
      ${className}`}
    >
      {text?.json ? documentToReactComponents(text.json) : <p>{text}</p>}
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onToggle}
        disabled={disabled}
        required={required}
        onInvalid={onInvalid}
      />
      <span className="checkmark"></span>
    </label>
  );
};

/* PropTypes */
Checkbox.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.string,
  checked: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onInvalid: PropTypes.func,
};
Checkbox.defaultProps = {
  className: "",
  checked: false,
  disabled: false,
};

/* Local utility functions */

/* Styles */

export default Checkbox;
