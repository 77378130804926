/* Global imports */
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

/* Local imports */

/* Component definition */
const Button = ({ className, text, onClick, type, disabled, link, target }) => {
  return link ? (
    <a
      href={link}
      target={target}
      className={`button button--${type} ${className} ${
        link ? "button--link" : ""
      }`}
    >
      {text}
      {type === "tertiary" && (
        <FontAwesomeIcon icon={faArrowRight} className="icon" />
      )}
      {type === "quaternary" && (
        <FontAwesomeIcon icon={faChevronRight} className="icon" />
      )}
    </a>
  ) : (
    <button
      className={`button button--${type} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {type === "tertiary" && (
        <FontAwesomeIcon icon={faArrowRight} className="icon" />
      )}
      {type === "quaternary" && (
        <FontAwesomeIcon icon={faChevronRight} className="icon" />
      )}
    </button>
  );
};
/* PropTypes */
Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"]),
  disabled: PropTypes.bool,
  link: PropTypes.string,
  target: PropTypes.string,
};
Button.defaultProps = {
  className: "",
  type: "primary",
  disabled: false,
};

/* Local utility functions */

/* Styles */

export default Button;
