import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "../components/button";
import DC_Bakery from "../helper/dc_bakery";
import { getValue } from "../helper/translation";
import ReactMarkdown from "react-markdown";
import cookiesContext from "../context/cookies/cookiesContext";

DC_Bakery.__init();
const CookieBanner = ({ microcopy }) => {
  const { cookies, cookiesSetted, updateCookies, cookiesSettedTo } = useContext(cookiesContext);
  const [selection, setSelection] = useState(cookies);

  const typesOfCookies = [
    "functionalCookies",
    "statisticalCookies",
    "marketingCookies",
  ];

  useEffect(() => {
    if (typeof window !== "undefined") {
      cookiesSettedTo(cookieExists("cookieConsent"));
    }
  }, []);

  useEffect(() => {
    if (!!cookiesSetted) {
      DC_Bakery.flush();
      if (cookieExists('statisticalCookies')) {
        if (typeof window !== "undefined") {
          window._paq = window._paq || []
          const consents = ['setConsentGiven', 'setCookieConsentGiven']
          consents.forEach(el => {
            window._paq.push([el])
          })
        }
      }
    }
  }, [cookiesSetted]);


  const changeSelection = index => {
    let state = [...selection];
    state[index] = !state[index];
    setSelection(state);
  };

  const writeCookie = (selectAll = false) => {
    let selectedCookies = selection;
    if (selectAll) {
      selectedCookies = [true, true, true];
    }

    createCookie("cookieConsent");
    cookiesSettedTo(true);

    selectedCookies.forEach((cookie, index) => {
      if (cookie) {
        return createCookie(typesOfCookies[index]);
      } else {
        return deleteCookie(typesOfCookies[index]);
      }
    });
    DC_Bakery.clean();
    updateCookies(selectedCookies);
  };

  const createCookie = cookie => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    return (document.cookie = `${cookie}=true; expires=${cookieDate.toUTCString()}; path=/;`);
  };
  const deleteCookie = cookie => {
    return (document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`);
  };

  const cookieExists = cookie => {
    return document?.cookie?.indexOf(cookie) > -1;
  };

  return cookiesSetted !== null && !cookiesSetted ? (
    <Container className="cookieBanner">
      <div className="cookieBannerInner">
        <div className="headline">{getValue("cookie.headline", microcopy)}</div>
        <div className="text">
          {getValue("cookie.text1", microcopy)}{" "}
          {getValue("cookie.text2", microcopy)}
          <ReactMarkdown>{getValue("cookie.text3", microcopy)}</ReactMarkdown>
        </div>
        <form className="formWrapper">
          {typesOfCookies.map((type, index) => (
            <label className="checkboxLabel" key={index} htmlFor={type}>
              <div className="checkboxWrapper">
                <input
                  name={type}
                  id={type}
                  type="checkbox"
                  disabled={index === 0}
                  checked={selection[index]}
                  onChange={() => changeSelection(index)}
                />
                <span className="slider"></span>
              </div>
              {getValue(`cookie.${type}`, microcopy)}
            </label>
          ))}
        </form>
        <div className="buttonContainer">
          <Button
            type="secondary"
            className="button"
            text={getValue("cookie.selection", microcopy)}
            onClick={() => writeCookie(false)}
          />
          <Button
            type="primary"
            className="button"
            text={getValue("cookie.acceptAll", microcopy)}
            onClick={() => writeCookie(true)}
          />
        </div>
      </div>
    </Container>
  ) : null;
};

CookieBanner.propTypes = {};

CookieBanner.defaultProps = {};

export default CookieBanner;
