/* Global imports */
import React from "react";
import PropTypes from "prop-types";
import Button from "./button";

/* Local imports */

/* Component definition */
const InputWithButton = ({
  className,
  type,
  value,
  placeholder,
  buttonText,
  onChange,
  onButtonClick,
  disabled,
}) => {
  const submitIfEnterKeyPressed = e => {
    if (e.keyCode === 13) {
      onButtonClick();
    }
  };

  return (
    <div className={`inputWithButton ${className}`}>
      <input
        className={`box`}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={submitIfEnterKeyPressed}
      />
      <Button
        className="button"
        onClick={onButtonClick}
        text={buttonText}
        disabled={disabled}
      />
    </div>
  );
};
/* PropTypes */
InputWithButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  buttonText: PropTypes.any.isRequired,
  onButtonClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
InputWithButton.defaultProps = {
  className: "",
  type: "text",
  value: "",
  disabled: false,
};

/* Local utility functions */

/* Styles */

export default InputWithButton;
