import React from "react";
import { useMediaQuery } from "react-responsive";
import DesktopNav from "../components/desktopNav";
import MobileNav from "../components/mobileNav";

const MainNav = ({ siteSettings }) => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setIsClient(true);
    }
  }, []);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isClient && isDesktop ? (
    <DesktopNav siteSettings={siteSettings} />
  ) : (
    <MobileNav siteSettings={siteSettings} />
  );
};

export default MainNav;
