/* Global imports */
import { faEnvelope, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import React, { useEffect } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import slugify from "slugify";
import moment from "moment";
import _ from "lodash";

import { getLocalizedLink } from "../helper/link";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
/* Local imports */
import Button from "./button";
import InputWithButton from "./InputWithButton";
import { SLUGIFY_OPTIONS } from "../helper/slugify";

/* Component definition */

const DesktopNav = ({ siteSettings }) => {
  const { events, agendas } = useStaticQuery(graphql`
    {
      events: allContentfulEvent(
        limit: 4
        sort: { order: DESC, fields: endDate }
      ) {
        edges {
          node {
            contentful_id
            id
            title
            subtitle
            description
            endDate(formatString: "YYYY-MM-DD")
            startDate(formatString: "YYYY-MM-DD")
            moveToPastEvents
            agendas {
              contentful_id
            }
          }
        }
      }
      agendas: allContentfulAgenda {
        nodes {
          id
          contentful_id
          node_locale
          agendaItems {
            startTime(fromNow: false)
            endTime(fromNow: false)
            sessions {
              id
              contentful_id
              node_locale
              description {
                description
              }
              hosts {
                name
                link
                image {
                  fluid {
                    base64
                    sizes
                    src
                    srcSet
                    srcWebp
                    aspectRatio
                  }
                  fixed(width: 150, quality: 100) {
                    src
                    srcSet
                    srcSetWebp
                    srcWebp
                    height
                    width
                  }
                }
              }
              mainTopic
              moderators {
                name
              }
              speakers {
                biography {
                  json
                }
                image {
                  fluid {
                    srcSet
                  }
                  title
                }
              }
              startTime(fromNow: false)
            }
          }
        }
      }
    }
  `);
  const { microcopy } = siteSettings;
  const [hovered, setHovered] = React.useState(null);
  const [sticky, setSticky] = React.useState(false);
  const [searchOpen, toggleSearchOpen] = useToggle(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const stickyRef = React.useRef();
  const subMenuRef = React.useRef();

  const arrEvents = events.edges.map(edge => {
    const ev = edge.node;
    return {
      ...ev,
      agendas: ev.agendas
        ? ev.agendas.map(agenda => {
            return agendas.nodes.find(
              node => node.contentful_id === agenda.contentful_id
            );
          })
        : [],
    };
  });
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const pastEvents = [];
  arrEvents.map(evnt => {
    if (moment(evnt.endDate).isBefore(todayDate) && evnt.moveToPastEvents) {
      pastEvents.push(evnt);
    }
  });

  const futureEvents = [];
  arrEvents.map(evnt => {
    if (
      moment(evnt.endDate).isSameOrAfter(todayDate) ||
      !evnt.moveToPastEvents
    ) {
      futureEvents.push(evnt);
    }
  });
  // const actualEvent = arrEvents.filter((ev, index) => index === 0)
  // const pastEvents = arrEvents.filter((ev, index) => index !== 0)

  const handleScroll = () => {
    window.scrollY > stickyRef.current.getBoundingClientRect().bottom
      ? setSticky(true)
      : setSticky(false);
    Array.from(document.getElementsByClassName("sub-menu")).forEach(
      el => (el.style.top = `${130 - window.scrollY}px`)
    );
  };

  const getLocalizedCurrentLink = (locale, currentPath) => {
    return locale === "de-DE"
      ? currentPath.replace("/en", "")
      : "/en" + currentPath;
  };

  const isPageActive = page => {
    const currentPathParts = window.location.pathname.split("/");
    const firstLevelNavigation =
      siteSettings.node_locale === "en"
        ? currentPathParts[2].replace("/", "")
        : currentPathParts[1].replace("/", "");

    return page.slug === firstLevelNavigation;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (searchOpen) {
      closeSubMenu();
    }
  }, [searchOpen]);

  const goToSearchPage = () => {
    navigate(
      getLocalizedLink(`/search?q=${searchTerm}`, siteSettings.node_locale)
    );

    // in the case we are already in the search page
    setSearchTerm("");
    toggleSearchOpen();
  };

  const closeSubMenu = () => {
    setHovered(false);
  };

  // Filter out all agendas that belong to events
  const sideEvents = agendas.nodes
    .filter(agenda => {
      const eventsAgendasIds = arrEvents
        .map(ev => ev.agendas.map(ag => ag.contentful_id))
        .flat();
      const agendaItems = agenda.agendaItems;
      return (
        !eventsAgendasIds.includes(agenda.contentful_id) && agendaItems.length
      );
    })
    .sort((a, b) => {
      return moment(a.agendaItems[0].startTime).isBefore(
        moment(b.agendaItems[0].startTime)
      )
        ? -1
        : 1;
    });

  let arrSpeakers = [],
    arrModerators = [],
    arrHosts = [];

  // Get speakers, hosts and moderators
  futureEvents
    .filter(event => event.agendas)
    .flatMap(event => event.agendas)
    .concat(sideEvents)
    .flatMap(({ agendaItems }) => [...agendaItems])
    .filter(agendaItem => agendaItem.sessions !== null)
    .flatMap(agendaItem => agendaItem.sessions)
    .filter(
      session =>
        session.speakers !== null ||
        session.moderators !== null ||
        session.hosts !== null
    )
    .forEach(({ speakers, moderators, hosts }) => {
      if (speakers) {
        speakers.forEach(speaker => arrSpeakers.push(speaker));
      }
      if (moderators) {
        moderators.forEach(moderator => arrModerators.push(moderator));
      }

      if (hosts) {
        hosts.forEach(host => arrHosts.push(host));
      }
    });

  arrSpeakers = _.uniqBy(arrSpeakers, "image.fluid.srcSet");
  arrModerators = _.uniqBy(arrModerators, "image.fluid.srcSet");
  arrHosts = _.uniqBy(arrHosts, "name");

  const renderSubMenu = (
    navigationItem,
    index,
    sticky,
    programmeSubMenu,
    hideSeeMoreButton
  ) => (
    <div className="sub-menu" ref={sticky ? null : subMenuRef}>
      <Collapse in={hovered === index}>
        <div>
          <Container fluid className="sub-menu__content">
            <Row>
              <Col className="sub-menu__links" md={3}>
                <span className="sub-menu-links__overview">
                  {getValue("labels.overview", microcopy)}
                </span>
                {programmeSubMenu ? (
                  <div>
                    {futureEvents && (
                      <div>
                        {futureEvents.map(ev => (
                          <Link
                            to={getLocalizedLink(
                              `/programme?r=${ev.contentful_id}`,
                              siteSettings.node_locale
                            )}
                            className="sub-menu-links__link"
                          >
                            {ev.title}
                          </Link>
                        ))}
                        {arrModerators.length > 0 || arrSpeakers.length > 0 ? (
                          <Link
                            to={getLocalizedLink(
                              `/programme?r=speakers`,
                              siteSettings.node_locale
                            )}
                            className="sub-menu-links__link"
                          >
                            {getValue("navigation.speakers", microcopy)}
                          </Link>
                        ) : null}
                        {arrHosts.length ? (
                          <Link
                            to={getLocalizedLink(
                              `/programme?r=hosts`,
                              siteSettings.node_locale
                            )}
                            className="sub-menu-links__link"
                          >
                            {getValue("navigation.hosts", microcopy)}
                          </Link>
                        ) : null}
                        {sideEvents.length ? (
                          <Link
                            to={getLocalizedLink(
                              `/programme?r=sideEvents`,
                              siteSettings.node_locale
                            )}
                            className="sub-menu-links__link"
                          >
                            {getValue("navigation.sideEvents", microcopy)}
                          </Link>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div>
                      {pastEvents &&
                        pastEvents.map(item => {
                          return (
                            <div>
                              <Link
                                key={item.title}
                                to={getLocalizedLink(
                                  `/past-event/${slugify(
                                    item.title,
                                    SLUGIFY_OPTIONS
                                  )}`,
                                  siteSettings.node_locale
                                )}
                                className="sub-menu-links__link"
                              >
                                {item.title}
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                    {pastEvents.length > 1 && (
                      <Button
                        className="sub-menu-see-more"
                        type="quaternary"
                        text={getValue("actions.showMore", microcopy)}
                        link={getLocalizedLink(
                          `/${navigationItem.slug}`,
                          siteSettings.node_locale
                        )}
                      />
                    )}
                  </>
                )}
              </Col>
              <Col md={{ span: 4, offset: 1 }}>
                <p>
                  {navigationItem.localizedPage.shortDescription &&
                    navigationItem.localizedPage.shortDescription.content[0]
                      .content[0].value}
                </p>
                {/* ticket https://dev.azure.com/cuisines/frient-website/_workitems/edit/29501 */}
                {!hideSeeMoreButton && (
                  <Button
                    className="sub-menu-see-more"
                    type="quaternary"
                    text={getValue("actions.seeMore", microcopy)}
                    link={getLocalizedLink(
                      `/${navigationItem.slug}`,
                      siteSettings.node_locale
                    )}
                  />
                )}
              </Col>
              <Col md={4}>
                {navigationItem.localizedPage.image &&
                  navigationItem.localizedPage.image.imageLandscape.fluid && (
                    <Image
                      fluid={
                        navigationItem.localizedPage.image &&
                        navigationItem.localizedPage.image.imageLandscape.fluid
                      }
                      style={{ width: "100%", height: "100%" }}
                      imgStyle={{ objectFit: "contain" }}
                    />
                  )}
              </Col>
            </Row>
          </Container>
        </div>
      </Collapse>
    </div>
  );

  return (
    <nav
      id="nav"
      className={`desktopNav d-flex align-items-center ${
        sticky ? "desktopNav--sticky" : ""
      }`}
      ref={stickyRef}
    >
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={sticky ? 2 : 3}>
            <Link to={getLocalizedLink("/", siteSettings.node_locale)}>
              <img
                src={
                  sticky
                    ? siteSettings.logoSticky.file.url
                    : siteSettings.logo.file.url
                }
                className={`logo ${sticky ? "logo--sticky" : ""}`}
                alt="Logo"
              />
            </Link>
          </Col>
          {sticky && (
            <Col xs={6} className="d-flex justify-content-between">
              {siteSettings.navigation.map((navigationItem, index) => {
                return (
                  <div
                    key={navigationItem.slug}
                    onMouseLeave={closeSubMenu}
                    role="menuitem"
                    tabIndex={index}
                  >
                    <Link
                      to={`/${navigationItem.slug}`}
                      className={`bottom__link ${
                        hovered === index &&
                        (navigationItem.localizedPage.name === "Programme" ||
                          navigationItem.localizedPage.name === "Past Events")
                          ? "bottom__link--open"
                          : isPageActive(navigationItem)
                          ? "bottom__link--active"
                          : ""
                      }`}
                      onMouseEnter={() => setHovered(index)}
                    >
                      {navigationItem.localizedPage.name}
                    </Link>
                    {/* {navigationItem.childPages &&
                  navigationItem.childPages.length > 0
                    ? renderSubMenu(navigationItem, index, true)
                    : null} */}
                    {navigationItem.localizedPage.name === "Programme" &&
                    futureEvents &&
                    futureEvents.length > 0
                      ? renderSubMenu(navigationItem, index, true, true, true)
                      : navigationItem.localizedPage.name === "Past Events" &&
                        pastEvents &&
                        pastEvents.length > 0
                      ? renderSubMenu(navigationItem, index, false, false)
                      : null}
                    {/* {navigationItem.localizedPage.name === "Past Events" && pastEvents && pastEvents.length > 0 
                      ? renderSubMenu(navigationItem, index, false, false)
                      : null
                    } */}
                  </div>
                );
              })}
            </Col>
          )}
          <Col>
            <Row className="top">
              <Col className="d-flex align-items-center justify-content-end">
                {!sticky && (
                  <>
                    <div className="top__link">
                      <FontAwesomeIcon icon={faEnvelope} className="icon" />
                      <Link to={`https://www.frient.de/en/newsletter`}>
                        Newsletter
                      </Link>
                    </div>
                  </>
                )}

                <Button
                  text={
                    <>
                      <img
                        src="/frient_signet@1x.svg"
                        alt="PBF logo"
                        className="frient-logo"
                        style={{ marginRight: "6px" }}
                      />
                      FriEnt.de
                    </>
                  }
                  className="top__button"
                  link="https://www.frient.de/en/"
                  target="_blank"
                />
                {sticky && (
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`icon icon__search ${
                      searchOpen ? "icon__search--open" : ""
                    }`}
                    onClick={toggleSearchOpen}
                  />
                )}
              </Col>
            </Row>
            {!sticky && (
              <Row className="justify-content-between bottom">
                <Col xs={9} className="d-flex justify-content-between">
                  {siteSettings.navigation.map((navigationItem, index) => (
                    <div
                      key={navigationItem.slug}
                      onMouseLeave={closeSubMenu}
                      role={"menuitem"}
                      tabIndex={index}
                    >
                      <Link
                        to={getLocalizedLink(
                          `/${navigationItem.slug}`,
                          siteSettings.node_locale
                        )}
                        className={`bottom__link ${
                          hovered === index &&
                          (navigationItem.localizedPage.name === "Programme" ||
                            navigationItem.localizedPage.name === "Past Events")
                            ? "bottom__link--open"
                            : isPageActive(navigationItem)
                            ? "bottom__link--active"
                            : ""
                        }`}
                        onMouseEnter={() => setHovered(index)}
                      >
                        {navigationItem.localizedPage.name}
                      </Link>
                      {/* {navigationItem.childPages &&
                      navigationItem.childPages.length > 0
                        ? renderSubMenu(navigationItem, index, false)
                        : null} */}
                      {navigationItem.localizedPage.name === "Programme" &&
                      futureEvents &&
                      futureEvents.length > 0
                        ? renderSubMenu(
                            navigationItem,
                            index,
                            false,
                            true,
                            true
                          )
                        : navigationItem.localizedPage.name === "Past Events" &&
                          pastEvents &&
                          pastEvents.length > 0
                        ? renderSubMenu(navigationItem, index, false, false)
                        : null}
                    </div>
                  ))}
                </Col>
                <Col lg="auto">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={`icon icon__search ${
                      searchOpen ? "icon__search--open" : ""
                    }`}
                    onClick={toggleSearchOpen}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <div className="sub-menu" ref={subMenuRef}>
          <Collapse in={searchOpen}>
            <div>
              <Container fluid className="sub-menu__content">
                <Row>
                  <Col>
                    <h4>{getValue("search.question", microcopy)}</h4>
                    <InputWithButton
                      buttonText={getValue("actions.search", microcopy)}
                      placeholder={getValue(
                        "placeholders.searchTerm",
                        microcopy
                      )}
                      onButtonClick={goToSearchPage}
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Collapse>
        </div>
      </Container>
    </nav>
  );
};
/* PropTypes */
DesktopNav.propTypes = {};
DesktopNav.defaultProps = {};

/* Local utility functions */

/* Styles */

export default DesktopNav;
