export const getValue = (key, dictionary) => {
  return dictionary?.[key] || key;
};

export const getLocalizedTags = (
  contentfulIds,
  possibleRelatedEntries,
  locale
) => {
  return contentfulIds.map(id => {
    const relatedEntry = possibleRelatedEntries.find(
      entry => entry && entry.contentful_id === id
    );
    return relatedEntry
      ? { ...relatedEntry, node_locale: locale }
      : { name: id, contentful_id: id, node_locale: locale };
  });
};
