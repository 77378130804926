import React from "react";
import MainNav from "./mainNav";

const Header = ({ siteSettings }) => (
  <header className={"bg-white"}>
    <MainNav siteSettings={siteSettings} />
  </header>
);

export default Header;
