import React from "react";
import { useMediaQuery } from "react-responsive";
import DesktopFooter from "../components/desktopFooter";
import MobileFooter from "../components/mobileFooter";


const Footer = ({ siteSettings }) => {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setIsClient(true);
    }
  }, []);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {isClient && isDesktop ? (
        <DesktopFooter siteSettings={siteSettings} />
      ) : (
        <MobileFooter siteSettings={siteSettings} />
      )}
    </>
  );
};

export default Footer;
