/* Global imports */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

/* Local imports */

/* Component definition */
const Bubble = ({ className, icon, text, link, target }) => {
  return (
    <div className={`bubble ${className}`}>
      <a href={link} target={target}>
        <div className="bubble__icon-container">
          <FontAwesomeIcon icon={icon} className="bubble__icon" />
        </div>
        <div className={"bubble__text-container"}>
          <span>{text}</span>
        </div>
      </a>
    </div>
  );
};
/* PropTypes */
Bubble.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};
Bubble.defaultProps = {
  className: "",
};

/* Local utility functions */

/* Styles */

export default Bubble;
