/* Global imports */
import axios from 'axios';
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Link, { getLocalizedLink } from "../helper/link";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
import Checkbox from "./checkbox";
/* Local imports */
import InputWithButton from "./InputWithButton";



const contactAddress =
  "Arbeitsgemeinschaft Frieden<br>und Entwicklung (FriEnt)<br>c/o GIZ<br><br>Friedrich-Ebert-Allee 36<br>53113 Bonn";

/* Local imports */

/* Component definition */
const DesktopFooter = ({ siteSettings }) => {

  const {globalPages} = useStaticQuery (graphql`
  {
    globalPages: allContentfulPageGlobal {
      edges {
        node {
          id
          node_locale
          slug
          title
        }
      }
    }
  }
  `)
  const { logoMobile, siteDescription, microcopy, footerAddress } = siteSettings;
  const [email, setEmail] = React.useState("");
  const [termsAccepted, toggleTermsAccepted] = useToggle(false);
  const [newsError, setNewsError] = React.useState(false)
  const [newsSuccesful, setnewsSuccesful] = React.useState(false)

  const setEmailValue = e => {
    setEmail(e.target.value);
  };

  const showingItems = []
  siteSettings.navigation.map(item => {
    globalPages.edges.map(global => {
      if((global.node.title === item.localizedPage.name )&& (item.localizedPage.name !== "About PBF") ) {
        showingItems.push(global)
      }
    })
  })
  
  const verifyEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const addEmailToList = async (email) => {
    try {
      if (verifyEmail(email)) {
        const response = await axios.post(`https://hooks.zapier.com/hooks/catch/9514566/oprj4g4?email=${email}`)
        setNewsError(false)
        setnewsSuccesful(true)
      } else {
        setnewsSuccesful(false)
        setNewsError(true)
      }
    } catch (error) {
      console.log(error)
      setnewsSuccesful(false)
      setNewsError(true)
    }
  }

  const actualYear = new Date().getFullYear()

  return (
    <footer className="desktopFooter">
      <div className="newsletter">
        <Container fluid>
          <h4 className="title">Impulse-Newsletter</h4>
          <InputWithButton
            className="email"
            placeholder={getValue("placeholders.email", microcopy)}
            buttonText={getValue("actions.subscribe", microcopy)}
            value={email}
            onChange={setEmailValue}
            onButtonClick={() => addEmailToList(email)}
            disabled={!termsAccepted || !email}
          />
          {newsError && (
            <div className="msg errorMsg">
              <small>You've searched a wrong email</small>
            </div>
          ) }
          {newsSuccesful && (
            <div className="msg successMsg">
              <small>Thanks! We've received your request</small>
            </div>
          ) }
          <Checkbox
            className="conditions"
            text={getValue("labels.terms", microcopy)}
            checked={termsAccepted}
            onToggle={toggleTermsAccepted}
          />
        </Container>
      </div>
      <div className="content">
        <Container fluid className={"px-4"}>
          <Row className="info">
            <Col md={4}>
              <img src={logoMobile.file.url} className="logo" alt="Logo" />
            </Col>
            <Col md={8}>
              <p className="description">{siteDescription.siteDescription}</p>
            </Col>
          </Row>
          <Row className="menu">
            <Col md={4}>
              <Link
                to={getLocalizedLink("/contact", siteSettings.node_locale)}
                className="mainLink"
              >
                {getValue("links.contact", microcopy)}
              </Link>
              <p
                className="address"
                dangerouslySetInnerHTML={{ __html: contactAddress }}
              ></p>
            </Col>
            <Col>
              <Row
                className="footerItemsContainer"
              >
                  {showingItems && (
                    <>
                      <Col
                        md={4}
                        className="mb-md-5"
                      >
                        {showingItems.map((item, index)=>{
                          return (
                          index <= 1 && (
                            <Link
                              to={getLocalizedLink(
                                `/${item.node.slug}`,
                                siteSettings.node_locale
                              )}
                              className="subLink"
                            >
                              {item.node.title}
                            </Link>
                          )
                        )}
                        )}
                      </Col>
                      <Col
                        md={4}
                        className="mb-md-5"
                      >
                        {showingItems.map((item, index)=>(
                          (index >= 2) && (index <= 3) ? (
                            <Link
                              to={getLocalizedLink(
                                `/${item.node.slug}`,
                                siteSettings.node_locale
                            )}
                              className="subLink"
                            >
                              {item.node.title}
                            </Link>
                          ) : (null)
                        ))}
                      </Col>
                    </>
                  )}
                  <Col
                    md={4}
                    // md={index !== 2 ? 3 : { span: 3, offset: 3 }}
                    // lg={index !== 2 ? 3 : { span: 3, offset: 0 }}
                    className="mb-md-5"
                    key={'pbf-2021'}
                  >
                        <Link
                          to={'https://www.frient.de/en'}
                          className="subLink"
                        >
                          {'Frient.de'}
                        </Link>
                        <Link
                          to={'https://www.frient.de/en/ueber-uns'}
                          className="subLink"
                        >
                          {'About Frient'}
                        </Link>
                  </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-between copyright">
            <Col md="auto" className="small">
              <div
                className="footerContentfulLogoContainer"
              >
                <span>© FriEnt {actualYear}</span>
                <a href="https://www.contentful.com/" rel="nofollow" target="_blank">
                  <img src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg" style={{maxWidth: "120px", width: "100%"}} alt="Powered by Contentful" />
                </a>
              </div>
            </Col>
            <Col className="d-flex justify-content-end secondaryLinks">
              {/* <Link to="/login" className="small">
                {getValue("links.login", microcopy)}
              </Link> */}
              <Link
                to={getLocalizedLink("/contact", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.contact", microcopy)}
              </Link>
              <Link
                to={getLocalizedLink("/imprint", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.imprint", microcopy)}
              </Link>
              <Link
                to={getLocalizedLink("/data-protection", siteSettings.node_locale)}
                className="small"
              >
                {getValue("links.dataProtection", microcopy)}
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
/* PropTypes */
DesktopFooter.propTypes = {};
DesktopFooter.defaultProps = {};

/* Local utility functions */

/* Styles */

export default DesktopFooter;
