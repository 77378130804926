/* Global imports */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getLocalizedLink } from "../helper/link";
import { getValue } from "../helper/translation";
import useToggle from "../hooks/useToggle";
import Checkbox from "./checkbox";
/* Local imports */
import InputWithButton from "./InputWithButton";

// const navigation = [
//   {
//     slug: "ueber-uns",
//     name: "Über uns",
//     "sub-navigation": [],
//   },
//   {
//     slug: "arbeitsbereiche",
//     name: "Arbeitsbereiche",
//     "sub-navigation": [],
//   },
//   {
//     slug: "publikationen",
//     name: "Publikationen",
//     "sub-navigation": [],
//   },
//   {
//     slug: "wissen",
//     name: "Wissen",
//     "sub-navigation": [],
//   },
// ];

const contactAddress =
  "Arbeitsgemeinschaft Frieden<br>und Entwicklung (FriEnt)<br>c/o GIZ<br><br>Friedrich-Ebert-Allee 36<br>53113 Bonn";

const actualYear = new Date().getFullYear();

/* Component definition */
const MobileFooter = ({ siteSettings }) => {
  const { logoMobile, siteDescription, microcopy } = siteSettings;
  const [email, setEmail] = React.useState("");
  const [termsAccepted, toggleTermsAccepted] = useToggle(false);
  const [newsError, setNewsError] = React.useState(false);
  const [newsSuccesful, setnewsSuccesful] = React.useState(false);

  const setEmailValue = e => {
    setEmail(e.target.value);
  };

  const verifyEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const addEmailToList = async email => {
    try {
      if (verifyEmail(email)) {
        const response = await axios.post(
          `https://hooks.zapier.com/hooks/catch/9514566/oprj4g4?email=${email}`
        );
        setNewsError(false);
        setnewsSuccesful(true);
      } else {
        setnewsSuccesful(false);
        setNewsError(true);
      }
    } catch (error) {
      console.log(error);
      setnewsSuccesful(false);
      setNewsError(true);
    }
  };

  const actualYear = new Date().getFullYear();

  return (
    <footer className="mobileFooter">
      <Container className="newsletter">
        <Row>
          <Col>
            <h4 className="title">Impulse-Newsletter</h4>
            <InputWithButton
              className="email"
              placeholder={getValue("placeholders.email", microcopy)}
              buttonText={
                <FontAwesomeIcon icon={faChevronRight} className="chevron" />
              }
              value={email}
              onChange={setEmailValue}
              onButtonClick={() => addEmailToList(email)}
              disabled={!termsAccepted || !email}
            />
            {newsError && (
              <div className="msg errorMsg">
                <small>You search a wrong email</small>
              </div>
            )}
            {newsSuccesful && (
              <div className="msg successMsg">
                <small>Thanks! We receive your request</small>
              </div>
            )}
            <Checkbox
              className="conditions"
              text={getValue("labels.terms", microcopy)}
              checked={termsAccepted}
              onToggle={toggleTermsAccepted}
            />
          </Col>
        </Row>
      </Container>
      <Container className="content">
        <Row>
          <Col>
            <img src={logoMobile.file.url} alt="Logo" className="logo" />
            <p className="description">{siteDescription.siteDescription}</p>
            {siteSettings.navigation.map(page => {
              if (page.slug === "publikationen") {
                return (
                  <div className="item" key={page.slug}>
                    <Link
                      to={getLocalizedLink(
                        `/artikel`,
                        siteSettings.node_locale
                      )}
                    >
                      {getValue("labels.articles", microcopy)}
                    </Link>
                  </div>
                );
              }

              return (
                <div className="item" key={page.slug}>
                  <Link to={`/${page.slug}`}>{page.localizedPage.name}</Link>
                </div>
              );
            })}
            <div className="contact item">
              <Link to="/kontakt">Kontakt</Link>
              <p
                className="address"
                dangerouslySetInnerHTML={{ __html: contactAddress }}
              ></p>
            </div>
            <div className="copyright">
              <div className="mobSecondaryLinks">
                {/* <Link to="/login" className="small">
                  {getValue("links.login", microcopy)}
                </Link> */}
                <Link
                  to={getLocalizedLink("/contact", siteSettings.node_locale)}
                  className="small"
                >
                  {getValue("links.contact", microcopy)}
                </Link>
                <Link
                  to={getLocalizedLink("/imprint", siteSettings.node_locale)}
                  className="small"
                >
                  {getValue("links.imprint", microcopy)}
                </Link>
                <Link
                  to={getLocalizedLink(
                    "/data-protection",
                    siteSettings.node_locale
                  )}
                  className="small"
                >
                  {getValue("links.dataProtection", microcopy)}
                </Link>
              </div>
              <div className="small mobFooterContentfulContainer">
                <span>© FriEnt {actualYear}</span>
                <a
                  href="https://www.contentful.com/"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                    style={{ maxWidth: "120px", width: "100%" }}
                    alt="Powered by Contentful"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
/* PropTypes */
MobileFooter.propTypes = {};
MobileFooter.defaultProps = {};

/* Local utility functions */

/* Styles */

export default MobileFooter;
